import { useEffect, useState, useRef } from 'react';
import { Card, Button, Form, Row, Col, Modal, ButtonGroup, ButtonToolbar, InputGroup, FormLabel, Table, Link } from 'react-bootstrap';
import { sendRequest } from '../../utilities/api';
import { Icon, Select, Tinymce } from '../../components';
import { convertPhoneFormat } from '../../utilities';
import CallWidget from './CallWidget';

const QuickConnect = ({ isBulk, ...props }) => {
    const [connect, setConnect] = useState(props.connect);
    const [notes, setNotes] = useState([]);
    const [note_type, setNoteType] = useState('manual');
    const [sendNote, setSendNote] = useState(false);
    const [meta, setMeta] = useState({});
    const [data, setData] = useState({ title: '', type: '', body: '', subject: '', notify_type: 'send', execution_date: '' });
    const [token, setToken] = useState('');
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showFrom, setShowFrom] = useState(false);
    const [showCc, setShowCc] = useState(false);
    const [showBcc, setShowBcc] = useState(false);

    const [paramsters, setParamsters] = useState({
        token: '',
        from_number: '',
        phoneno: connect.to,
        record: "false",
        client_id: connect.id,
        coach_id: connect.coach_id,
        phone: "",
        mobile: "",
        first_name: connect.first_name,
        last_name: connect.last_name
    });

    const [fileList, setFileList] = useState([]);

    const handleFileChange = (event) => {
        const newFiles = Array.from(event.target.files);

        // Merge the new files with the existing ones and remove duplicates
        const updatedFiles = [...fileList, ...newFiles].filter(
            (file, index, self) =>
                index === self.findIndex((f) => f.name === file.name && f.size === file.size)
        );

        setFileList(updatedFiles);
    };

    const handleFileRemove = (index) => {
        const updatedFiles = fileList.filter((_, i) => i !== index);
        setFileList(updatedFiles);
    };


    const textareaRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            if (connect.type == 'note') {
                await getNotes(connect.id, note_type);
            } else if (connect.type == 'sms' || connect.type == 'email') {
                await getTemplateMeta();
            } else if (connect.type == 'call') {
                await getVoiceToken();
            } else if (connect.type == 'activity') {
                await getReportDetails();
            }
        };
        fetchData();
    }, []);


    const getReportDetails = async () => {
        setLoading(false);
        await sendRequest(`crm/report/${connect.id}/details`, 'GET', []).then(response => {
            if (response.status === true) {
                setData(response.data.list)
                setLoading(true);
            }
        })
            .catch(error => {
                setLoading(true);
                console.error('GET request error:', error);
            });
    }

    const getVoiceToken = async () => {
        setLoading(false);
        await sendRequest(`voice/token`, 'GET', []).then(response => {
            if (response.status === true) {
                setParamsters((prevState) => ({
                    ...prevState,
                    from_number: response.data.outbound_phone,
                    token: response.data.token,

                }));
                setLoading(true);
            }
        })
            .catch(error => {
                setLoading(true);
                console.error('GET request error:', error);
            });
    }

    const getTemplateMeta = async (id, type) => {
        setLoading(false);
        const formData = new FormData();
        formData.set('type', connect.type)
        formData.set('is_bulk', isBulk ? 1 : 0)

        await sendRequest(`crm/templates/meta`, 'POST', formData).then(response => {
            if (response.status === true) {
                setMeta(response.data);
                setLoading(true);
            }
        })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    }

    const getNotes = async (id, type) => {
        setLoading(false);
        const formData = new FormData();
        formData.set('note_type', type)
        await sendRequest(`crm/leads/activity/${id}/note`, 'POST', formData).then(response => {
            if (response.status === true) {
                setLoading(true);
                setNotes(response.data.lists);
            }
        })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    }

    const insertValueAtCursor = (valueToInsert) => {
        const textarea = textareaRef.current;
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;
        const newText = data.body.substring(0, start) + valueToInsert + data.body.substring(end);
        setData((prevState) => ({
            ...prevState,
            body: newText,
        }));

        // Set the cursor position after the inserted value
        textarea.selectionStart = start + valueToInsert.length;
        textarea.selectionEnd = start + valueToInsert.length;
        textarea.focus();
    };

    const handleSubmit = (event) => {
        setIsLoading(true);
        event.preventDefault();

        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        if (connect.to != '' && !connect.is_bulk) {
            submitData.append('tos', connect.to);
        }

        if (connect.campaign_id != '') {
            submitData.append('campaign_id', connect.campaign_id);
        }

        submitData.append('lead_id', connect.id);
        submitData.append('type', connect.type);
        submitData.append('is_bulk', isBulk ? 1 : 0);

        fileList.forEach((file, index) => {
            submitData.append(`attachments[${index}]`, file);
        });
    
        sendRequest(`crm/leads/add/activity/${connect.id}/${connect.type}`, 'POST', submitData).then(response => {
            if (response.status === true) {
                if (connect.type == 'note') {
                    setSendNote(false);
                    getNotes(connect.id, note_type);
                } else {
                    props.quickAction(true, {});
                }
            }
            setIsLoading(false);
        })
            .catch(error => {
                setIsLoading(false);
                console.error('POST request error:', error);
            });
    }

    const updateAction = () => {
        props.quickAction(true, {});
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    return (
        <div>

            <Modal size='lg' show={connect.modal} onHide={() => updateAction()} >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {
                            connect.type == 'note' ? "Add/View Notes" :
                                connect.type == 'sms' ? "Send SMS/MMS" :
                                    connect.type == 'email' ? "Send Email" :
                                        connect.type == 'activity' ? "View Report Details"
                                            : ''
                        }

                        {/* {connect.to} */}
                    </Modal.Title>
                </Modal.Header>
                {
                    loading &&
                    <>
                        {
                            connect.type == 'note' ?

                                (
                                    <Modal.Body>
                                        <div className='d-flex note-type justify-content-between'>
                                            <Form.Select
                                                size="sm"
                                                name="note_type"
                                                className='w-50'
                                                defaultValue={note_type}
                                                onChange={(e) => { getNotes(connect.id, e.target.value); setNoteType(e.target.value) }}
                                                required
                                            >
                                                <option value={'manual'}>Manual</option>
                                                <option value={'system'}>System</option>
                                                <option value={'all'}>All</option>
                                            </Form.Select>

                                            {
                                                note_type == 'manual' && (
                                                    <button
                                                        onClick={() => setSendNote(true)}
                                                        className="btn btn-sm btn-primary"
                                                    >
                                                        <Icon name="plus-sm"></Icon>

                                                    </button>
                                                )
                                            }

                                        </div>
                                        {
                                            sendNote && (

                                                <Form onSubmit={handleSubmit}>
                                                    <Form.Group className="form-group mt-1">
                                                        <div className="form-control-wrap">
                                                            <Form.Control as={'textarea'} name="activity" placeholder="Add Note" required />
                                                        </div>
                                                    </Form.Group>
                                                    <Col className="col-12">
                                                        <Button disabled={isLoading} size='sm' className='m-1' type="submit">Submit</Button>
                                                        <Button size='sm' className='m-1' onClick={() => updateAction()} variant='secondary' type="button">Close</Button>

                                                    </Col>
                                                </Form>
                                            )
                                        }
                                        <ul className='nk-schedule mt-4'>
                                            {
                                                notes.map((row, i) => {
                                                    return (
                                                        <li key={i} className='nk-schedule-item'>
                                                            <div class="nk-schedule-item-inner">
                                                                <div class="nk-schedule-symbol active"></div>
                                                                <div class="nk-schedule-content"><span class="smaller">{row.dated}</span>
                                                                    <div class="p"><b className='text-capitalize'> {row.type} :</b> {row.activity}</div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>

                                    </Modal.Body>
                                ) : connect.type == 'sms' ? (
                                    <Modal.Body>
                                        <Form onSubmit={handleSubmit}>

                                            {
                                                isBulk && (

                                                    <div className="bulkType">
                                                        <Row className='g-1'>
                                                            <Col md="6">
                                                                <Form.Group className="form-group">
                                                                    <Form.Label htmlFor="throttle_duration">Throttle duration</Form.Label>
                                                                    <div className="form-control-wrap">
                                                                        <Form.Select defaultValue={data.throttle_duration} name="throttle_duration" className="form-controll">
                                                                            <option value="15">15 minutes </option>
                                                                            <option value="30">30 minutes</option>
                                                                            <option value="60">60 minutes</option>
                                                                        </Form.Select>
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>

                                                            <Col md="6">
                                                                <Form.Group className="form-group">
                                                                    <Form.Label htmlFor="throttle_value">Throttle value</Form.Label>
                                                                    <div className="form-control-wrap">
                                                                        <Form.Select defaultValue={data.throttle_value} name="throttle_value" className="form-controll">
                                                                            <option value="5">5</option>
                                                                            <option value="10">10</option>
                                                                            <option value="15">15</option>
                                                                            <option value="25">25</option>
                                                                            <option value="50">50</option>
                                                                            <option value="100">100</option>
                                                                            <option value="150">150</option>
                                                                            <option value="200">200</option>
                                                                            <option value="250">250</option>
                                                                            <option value="300">300</option>
                                                                            <option value="350">350</option>
                                                                            <option value="400">400</option>
                                                                            <option value="450">450</option>
                                                                            <option value="500">500</option>
                                                                        </Form.Select>
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>

                                                            <Col md="12">

                                                                <Form.Check inline
                                                                    label="Send Now"
                                                                    type="radio"
                                                                    id="inlineradio1"
                                                                    value={'send'}
                                                                    defaultChecked={data.notify_type == 'send' ? true : false}
                                                                    onChange={(e) =>
                                                                        setData((prevState) => ({
                                                                            ...prevState,
                                                                            'notify_type': 'send',
                                                                        }))
                                                                    }
                                                                    name="notify_type"
                                                                />

                                                                <Form.Check inline
                                                                    label="Schedule"
                                                                    type="radio"
                                                                    value={'schedule'}
                                                                    id="inlineradio1"
                                                                    defaultChecked={data.notify_type == 'schedule' ? true : false}
                                                                    onChange={(e) =>
                                                                        setData((prevState) => ({
                                                                            ...prevState,
                                                                            'notify_type': 'schedule',
                                                                        }))
                                                                    }
                                                                    name="notify_type"
                                                                />




                                                            </Col>

                                                        </Row>


                                                        {data.notify_type == "schedule" && (
                                                            <Row className='g-1 mb-2'>
                                                                <Col md="6">

                                                                    <Form.Group className="form-group">
                                                                        <Form.Label htmlFor="execution_date">Execution Date:</Form.Label>
                                                                        <div className="form-control-wrap">
                                                                            <Form.Control
                                                                                value={data.execution_date}
                                                                                onChange={(e) => setData((prevState) => ({
                                                                                    ...prevState,
                                                                                    'execution_date': e.target.value,
                                                                                }))}
                                                                                name="execution_date"
                                                                                type='date'
                                                                                placeholder="Enter body"
                                                                                required />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col md="6">
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label htmlFor="execution_time">Execution Time:</Form.Label>
                                                                        <div className="form-control-wrap">
                                                                            <Select name="execution_time" onChange={(e) => {

                                                                                setData((prevState) => ({
                                                                                    ...prevState,
                                                                                    'execution_time': e,
                                                                                }));
                                                                            }}>
                                                                                <option value="">Select Time</option>
                                                                                {
                                                                                    meta.timeDuration && meta?.timeDuration.map((row, i) => {
                                                                                        return <option key={i} value={row}>{row}</option>
                                                                                    })
                                                                                }
                                                                            </Select>
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>

                                                        )}


                                                    </div>

                                                )
                                            }

                                            <Col md="12">





                                                <Select name="template_id" onChange={(e) => {
                                                    const desiredObject = meta?.smsTemplate.find(item => item.id == e[0]);
                                                    const body = desiredObject?.body || "";
                                                    setData((prevState) => ({
                                                        ...prevState,
                                                        body: body,
                                                    }));
                                                }}>
                                                    <option value="">Select Template</option>
                                                    {
                                                        meta?.smsTemplate && meta.smsTemplate.map((row, i) => {
                                                            return <option key={i} value={row.id}>{row.title}</option>
                                                        })
                                                    }
                                                </Select>


                                                <Select name="froms">
                                                    <option value="">Select Phone Number</option>
                                                    {
                                                        meta?.phone_numbers && meta.phone_numbers.map((row, i) => {
                                                            return <option key={i} value={row.number}>{convertPhoneFormat(row.number)}</option>
                                                        })
                                                    }
                                                </Select>

                                                <div className="token-button">

                                                    <Select className="as" removeItemButton searchEnabled onChange={(e) => setToken(`${e[0] ?? ''}`)}>
                                                        <option value=''>Select Token</option>
                                                        <optgroup label="Default Fields">
                                                            {
                                                                meta?.tokens?.default_fields && meta.tokens.default_fields.map((row, i) => {
                                                                    return <option key={row.key} value={`{${row.key}}`}>{row.label}</option>;
                                                                })
                                                            }
                                                        </optgroup>
                                                        <optgroup label="Custom Fields">
                                                            {
                                                                meta?.tokens?.custom_fields && meta.tokens.custom_fields.map((row, i) => {
                                                                    return <option key={row.key} value={`{${row.key}}`}>{row.label}</option>;
                                                                })
                                                            }
                                                        </optgroup>
                                                    </Select>
                                                    <Button size='sm' type="button" onClick={() => insertValueAtCursor(` ${token}`)} variant="outline-secondary">
                                                        Insert Token
                                                    </Button>

                                                </div>

                                                <Form.Group className="form-group mt-4">
                                                    <div className="form-control-wrap">
                                                        <Form.Control
                                                            ref={textareaRef}
                                                            value={data.body}
                                                            onChange={handleChange}
                                                            as="textarea"
                                                            name="body"
                                                            placeholder="Enter body"
                                                            required />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col className="col-12">
                                                <Button disabled={isLoading} size='sm' className='m-1' type="submit">Submit</Button>
                                                <Button size='sm' className='m-1' onClick={() => updateAction()} variant='secondary' type="button">Close</Button>

                                            </Col>
                                        </Form>
                                    </Modal.Body>
                                ) : connect.type == 'email' ? (
                                    <Modal.Body style={{ height: '100vh - 200px' }}>
                                        <Form onSubmit={handleSubmit}>

                                            {
                                                isBulk && (

                                                    <div className="bulkType">
                                                        <Row className='g-1'>
                                                            <Col md="6">
                                                                <Form.Group className="form-group">
                                                                    <Form.Label htmlFor="throttle_duration">Throttle duration</Form.Label>
                                                                    <div className="form-control-wrap">
                                                                        <Form.Select defaultValue={data.throttle_duration} name="throttle_duration" className="form-controll">
                                                                            <option value="15">15 minutes </option>
                                                                            <option value="30">30 minutes</option>
                                                                            <option value="60">60 minutes</option>
                                                                        </Form.Select>
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>

                                                            <Col md="6">
                                                                <Form.Group className="form-group">
                                                                    <Form.Label htmlFor="throttle_value">Throttle value</Form.Label>
                                                                    <div className="form-control-wrap">
                                                                        <Form.Select defaultValue={data.throttle_value} name="throttle_value" className="form-controll">
                                                                            <option value="5">5</option>
                                                                            <option value="10">10</option>
                                                                            <option value="15">15</option>
                                                                            <option value="25">25</option>
                                                                            <option value="50">50</option>
                                                                            <option value="100">100</option>
                                                                            <option value="150">150</option>
                                                                            <option value="200">200</option>
                                                                            <option value="250">250</option>
                                                                            <option value="300">300</option>
                                                                            <option value="350">350</option>
                                                                            <option value="400">400</option>
                                                                            <option value="450">450</option>
                                                                            <option value="500">500</option>
                                                                        </Form.Select>
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>

                                                            <Col md="12">
                                                                <Form.Check inline
                                                                    label="Send Now"
                                                                    type="radio"
                                                                    id="inlineradio1"
                                                                    value={'send'}
                                                                    defaultChecked={data.notify_type == 'send' ? true : false}
                                                                    onChange={(e) =>
                                                                        setData((prevState) => ({
                                                                            ...prevState,
                                                                            'notify_type': 'send',
                                                                        }))
                                                                    }
                                                                    name="notify_type"
                                                                />

                                                                <Form.Check inline
                                                                    label="Schedule"
                                                                    type="radio"
                                                                    value={'schedule'}
                                                                    id="inlineradio1"
                                                                    defaultChecked={data.notify_type == 'schedule' ? true : false}
                                                                    onChange={(e) =>
                                                                        setData((prevState) => ({
                                                                            ...prevState,
                                                                            'notify_type': 'schedule',
                                                                        }))
                                                                    }
                                                                    name="notify_type"
                                                                />




                                                            </Col>

                                                        </Row>

                                                        {data.notify_type == "schedule" && (
                                                            <Row className='g-1 mb-2'>
                                                                <Col md="6">
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label htmlFor="execution_date">Execution Date:</Form.Label>
                                                                        <div className="form-control-wrap">
                                                                            <Form.Control
                                                                                value={data.execution_date}
                                                                                onChange={(e) => setData((prevState) => ({
                                                                                    ...prevState,
                                                                                    'execution_date': e.target.value,
                                                                                }))}
                                                                                name="execution_date"
                                                                                type='date'
                                                                                placeholder="Enter body"
                                                                                required />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col md="6">
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label htmlFor="execution_time">Execution Time:</Form.Label>
                                                                        <div className="form-control-wrap">
                                                                            <Select name="execution_time" onChange={(e) => {

                                                                                setData((prevState) => ({
                                                                                    ...prevState,
                                                                                    'execution_time': e,
                                                                                }));
                                                                            }}>
                                                                                <option value="">Select Time</option>
                                                                                {
                                                                                    meta.timeDuration && meta?.timeDuration.map((row, i) => {
                                                                                        return <option key={i} value={row}>{row}</option>
                                                                                    })
                                                                                }
                                                                            </Select>
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>

                                                        )}


                                                    </div>

                                                )
                                            }
                                            <Col md="12">

                                                <Select name="template_id" onChange={(e) => {
                                                    const desiredObject = meta?.emailTemplate.find(item => item.id == e[0]);
                                                    const body = desiredObject?.body || "";
                                                    const subject = desiredObject?.title || "";
                                                    setData((prevState) => ({
                                                        ...prevState,
                                                        body: body,
                                                        subject: subject,

                                                    }));
                                                }}>
                                                    <option value="">Select Template</option>
                                                    {
                                                        meta?.emailTemplate && meta.emailTemplate.map((row, i) => {
                                                            return <option key={i} value={row.id}>{row.title}</option>
                                                        })
                                                    }
                                                </Select>

                                                <div className="d-none">
                                                    <Select name="froms">
                                                        <option value="">Select Phone Number</option>
                                                        {
                                                            meta?.phone_numbers && meta.phone_numbers.map((row, i) => {
                                                                return <option key={i} value={row.number}>{convertPhoneFormat(row.number)}</option>
                                                            })
                                                        }
                                                    </Select>
                                                </div>

                                                <div className="token-button d-none">
                                                    <Select className="as" removeItemButton searchEnabled onChange={(e) => setToken(`${e[0] ?? ''}`)}>
                                                        <option value=''>Select Token</option>
                                                        <optgroup label="Default Fields">
                                                            {
                                                                meta?.tokens?.default_fields && meta.tokens.default_fields.map((row, i) => {
                                                                    return <option key={row.key} value={`{${row.key}}`}>{row.label}</option>;
                                                                })
                                                            }
                                                        </optgroup>
                                                        <optgroup label="Custom Fields">
                                                            {
                                                                meta?.tokens?.custom_fields && meta.tokens.custom_fields.map((row, i) => {
                                                                    return <option key={row.key} value={`{${row.key}}`}>{row.label}</option>;
                                                                })
                                                            }
                                                        </optgroup>
                                                    </Select>

                                                    <Button type="button" onClick={() => setData((prevState) => ({
                                                        ...prevState,
                                                        subject: data.subject + ` ${token}`
                                                    }))}
                                                        variant="outline-secondary">
                                                        Insert Subject
                                                    </Button>

                                                </div>

                                                <Form.Group className="form-group mt-4">
                                                    <div className="form-control-wrap">
                                                        <Form.Control
                                                            value={data.subject}
                                                            onChange={handleChange}
                                                            name="subject"
                                                            placeholder="Enter subject"
                                                            required />
                                                    </div>
                                                </Form.Group>

                                                <div className="d-flex align-items-center font-size-sm mt-1">
                                                    <span className="mr-4 text-secondary" role='button'>
                                                        <u className="text-primary" onClick={() => setShowFrom(!showFrom)}>From Email</u>
                                                    </span>
                                                    <span className="text-muted" style={{ margin: '0 8px' }}>|</span>
                                                    <span className="mr-4 text-secondary" >
                                                        <u className="text-primary" role='button' onClick={() => setShowCc(!showCc)}>CC</u>
                                                    </span>
                                                    <span className="text-muted" style={{ margin: '0 8px' }}>|</span>
                                                    <span className="mr-4 text-secondary" >
                                                        <u className="text-primary" role='button' onClick={() => setShowBcc(!showBcc)}>BCC</u>
                                                    </span>
                                                </div>

                                                <Row className="align-items-center">
                                                    {
                                                        showFrom && (
                                                            <>
                                                                <Col className='mb-1' md="6">
                                                                    <Form.Control
                                                                        defaultValue={meta?.default_email_sender?.from_name}
                                                                        onChange={handleChange}
                                                                        name="from_name"
                                                                        placeholder="From name"
                                                                    />
                                                                </Col>
                                                                <Col className='mb-1' md="6">
                                                                    <Form.Control
                                                                        defaultValue={meta?.default_email_sender?.from_email}
                                                                        onChange={handleChange}
                                                                        name="from_email"
                                                                        placeholder="From email"
                                                                    />
                                                                </Col>
                                                            </>
                                                        )
                                                    }

                                                    {
                                                        showCc && (
                                                            <Col md="6">
                                                                <Form.Control
                                                                    defaultValue={data.cc}
                                                                    onChange={handleChange}
                                                                    name="cc"
                                                                    placeholder="CC (e.g., user1@example.com,user2@example.com)"
                                                                />
                                                            </Col>
                                                        )
                                                    }

                                                    {
                                                        showBcc && (
                                                            <Col md="6">
                                                                <Form.Control
                                                                    defaultValue={data.bcc}
                                                                    onChange={handleChange}
                                                                    name="bcc"
                                                                    placeholder="BCC (e.g., user1@example.com,user2@example.com)"
                                                                />
                                                            </Col>
                                                        )
                                                    }

                                                </Row>

                                                <Form.Group className="form-group mt-2">
                                                    <Tinymce
                                                        leadToken={true} height={500} userToken={false} initialValue={data.body} textareaName={'body'} toolbar={true}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="form-group mt-2">
                                                    <Row>
                                                        <Col md="12">
                                                            <div
                                                                className="attach-file"
                                                                style={{
                                                                    border: '2px dashed #ddd',
                                                                    borderRadius: '5px',
                                                                    padding: '15px',
                                                                    backgroundColor: '#f9f9f9',
                                                                    textAlign: 'center',
                                                                    position: 'relative',
                                                                }}
                                                                onDragOver={(e) => e.preventDefault()}
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    handleFileChange({ target: { files: e.dataTransfer.files } });
                                                                }}
                                                            >
                                                                <div className="attach-file-input" style={{ marginBottom: '10px' }}>
                                                                    <input
                                                                        type="file"
                                                                        multiple
                                                                        onChange={handleFileChange}
                                                                        style={{ display: 'none' }}
                                                                        id="fileInput"
                                                                    />
                                                                    <label
                                                                        htmlFor="fileInput"
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                            padding: '10px 15px',
                                                                            backgroundColor: '#007bff',
                                                                            color: '#fff',
                                                                            borderRadius: '3px',
                                                                            display: 'inline-block',
                                                                            marginBottom: '10px',
                                                                        }}
                                                                    >
                                                                        Attach File
                                                                    </label>
                                                                    <p style={{ color: '#6c757d', fontSize: '14px' }}>
                                                                        Drag & Drop files here or click "Attach File"
                                                                    </p>
                                                                </div>

                                                                {fileList.length > 0 && (
                                                                    <div className="attach-file-list" style={{ textAlign: 'left' }}>
                                                                        <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                                                                            {fileList.map((file, index) => (
                                                                                <li
                                                                                    key={index}
                                                                                    style={{
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                        justifyContent: 'space-between',
                                                                                        padding: '5px 0',
                                                                                        borderBottom: '1px solid #ddd',
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        className="file-name"
                                                                                        style={{ fontSize: '14px', fontWeight: '500', color: '#333' }}
                                                                                    >
                                                                                        {file.name}
                                                                                    </span>
                                                                                    <span
                                                                                        className="file-size"
                                                                                        style={{ fontSize: '12px', color: '#6c757d' }}
                                                                                    >
                                                                                        ({Math.round(file.size / 1024)} KB)
                                                                                    </span>
                                                                                    <span
                                                                                        onClick={() => handleFileRemove(index)}
                                                                                        className="file-remove"
                                                                                        style={{
                                                                                            fontSize: '12px',
                                                                                            color: '#dc3545',
                                                                                            cursor: 'pointer',
                                                                                            marginLeft: '10px',
                                                                                        }}
                                                                                        onMouseEnter={(e) => (e.target.style.textDecoration = 'underline')}
                                                                                        onMouseLeave={(e) => (e.target.style.textDecoration = 'none')}
                                                                                    >
                                                                                        Remove
                                                                                    </span>
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>


                                            </Col>
                                            <Col className="col-12">
                                                <Button disabled={isLoading} size='sm' className='m-1' type="submit">Submit</Button>
                                                <Button size='sm' className='m-1' onClick={() => updateAction()} variant='secondary' type="button">Close</Button>

                                            </Col>
                                        </Form>
                                    </Modal.Body>
                                )
                                    : connect.type == 'activity' ? (
                                        <Modal.Body>
                                            <Table responsive striped className="small table-bordered">
                                                <tbody>
                                                    <tr>
                                                        <th>From</th>
                                                        <td>{data.from}</td>
                                                        <th>To</th>
                                                        <td>{data.to}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Name</th>
                                                        <td>{data.name}</td>
                                                        <th>Email</th>
                                                        <td>{data.email}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Type</th>
                                                        <td className='text-capitalize'>{data.type}</td>
                                                        <th>Status</th>
                                                        <td className='text-capitalize'>{data.status}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Direction</th>
                                                        <td className='text-capitalize'>{data.direction}</td>

                                                    </tr>
                                                    <tr>
                                                        <td colSpan={4}>
                                                            <div className='area'>
                                                                {
                                                                    data.type == 'email' ? (
                                                                        <div className='email-area'>
                                                                            <div className='p'><b>Subject : </b> {data.subject} </div>
                                                                            <div className='body'>
                                                                                <div dangerouslySetInnerHTML={{ __html: data.body }} />
                                                                            </div>

                                                                            {data.attachments.length > 0 && (
                                                                                <div className="attachments border p-3 mt-3 rounded" style={{ backgroundColor: '#f9f9f9', borderColor: '#ddd' }}>
                                                                                    <h5 className="title mb-3" style={{ fontSize: '16px', fontWeight: '600', color: '#444' }}>
                                                                                        Attachments
                                                                                    </h5>
                                                                                    <ul className="list-unstyled">
                                                                                        {data.attachments.map((file, index) => (
                                                                                            <li key={index} className="d-flex align-items-center py-2" style={{ borderBottom: '1px solid #eee' }}>
                                                                                                <span
                                                                                                    className="file-name"
                                                                                                    style={{
                                                                                                        fontSize: '14px',
                                                                                                        fontWeight: '500',
                                                                                                        color: '#007bff',
                                                                                                        cursor: 'pointer',
                                                                                                        textDecoration: 'underline',
                                                                                                    }}
                                                                                                >
                                                                                                    <a
                                                                                                        href={file.path}
                                                                                                        target="_blank"
                                                                                                        rel="noopener noreferrer"
                                                                                                        style={{ textDecoration: 'none', color: '#007bff' }}
                                                                                                    >
                                                                                                        {file.name}
                                                                                                    </a>
                                                                                                </span>
                                                                                            </li>
                                                                                        ))}
                                                                                    </ul>
                                                                                </div>
                                                                            )}

                                                                        </div>
                                                                    ) :
                                                                        (data.type == 'sms' || data.type == 'mms') ? (
                                                                            <div className='sms-area'>
                                                                                <div className='body'>
                                                                                    <div dangerouslySetInnerHTML={{ __html: data.body }} />
                                                                                </div>

                                                                            </div>
                                                                        ) : (data.type == 'call') ? (
                                                                            <div className='call-area'>
                                                                                <div className='body'>
                                                                                    <div dangerouslySetInnerHTML={{ __html: data.body }} />
                                                                                </div>

                                                                            </div>
                                                                        )
                                                                            :
                                                                            (
                                                                                <>

                                                                                </>
                                                                            )
                                                                }
                                                                {
                                                                    data.media_url != '' && (
                                                                        <div className='attachment mt-3'>
                                                                            <a title='View' href={data.media_url} className='btn btn-link p-0'>
                                                                                View Media
                                                                            </a>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Modal.Body>
                                    )
                                        :
                                        (
                                            <>

                                            </>
                                        )
                        }
                    </>
                }

            </Modal>

            {
                !connect.modal && (
                    <div className='call'>
                        <CallWidget paramsters={paramsters} isMaximized={true} />
                    </div>
                )
            }
        </div >
    );
};

export default QuickConnect