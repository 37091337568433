import React, { useEffect, useState } from 'react';

import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { useLocation } from "react-router-dom";

// import Layout from '../../layout/fullpage';
import Layout from '../../layout/default';
import { sendRequest } from '../../utilities/api';
import Swal from 'sweetalert2';

function OutlookCallbackPage() {

    const location = useLocation();

    useEffect(() => {
        // Extracting parameters from the URL
        const queryParams = new URLSearchParams(location.search);
        const code = queryParams.get('code');
        const state = queryParams.get('state');
        const scope = queryParams.get('scope');

        if (code) {
            const payload = new FormData();

            payload.append('code', code);
            payload.append('scope', scope);
            payload.append('state', state);

            sendRequest(`microsoft/outlook-api/callback`, 'POST', payload).then(response => {
                
                if (response.status === true) {
                    Swal.fire({
                        title: 'Your account configured successfully',
                        icon: 'success',
                    }).then(function () {
                        if (state == 'wizard_page') {
                            window.location.href = '/settings-wizard';
                            return
                        }
                        if (state == 'mailbox_page') {
                            window.location.href = '/setting-manage/mailbox-settings';
                            return
                        }
                        
                        window.location.href = '/setting-manage/email/lists';
                    });
                }
            })

                .catch(error => {
                    console.error('POST request error:', error);
                });
        }
    }, [location]);

    return (
        <Layout title="Saving your data" centered>
            <div className="container p-2 p-sm-4">
                <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth card-auth-mh d-flex align-items-center justify-content-center" style={{ height: '300px' }}>
                    <div className="text-center">
                        <div className="spinner-border text-primary" role="status" style={{ width: '3rem', height: '3rem' }}>
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        <p className="mt-3">Saving your data, please wait...</p>
                    </div>
                </Card>
            </div>
        </Layout>
    )
}

export default OutlookCallbackPage;
