
import { useEffect, useState } from "react";
import { Dropdown, Form } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import classNames from "classnames";
import { useParams } from 'react-router-dom';
import { sendRequest } from "../../utilities/api";
import { useQuill } from 'react-quilljs';


import Layout from '../../layout/default';

import {
    CustomDropdownMenu,
    CustomDropdownToggle,
    Icon,
    Image,
    MediaGroup,
    Media,
    MediaText,
    Inbox,
    Reply
} from "../../components";

import { Break, toFirstCapital } from "../../utilities";

function Mailbox() {
    const { id } = useParams();
    const { quill, quillRef } = useQuill();


    const [selectedIndex, setSelectedIndex] = useState(0);
    const [showInbox, setShowInbox] = useState(false);
    const [sidebar, setSidebar] = useState(false);
    const [inboxData, setInboxData] = useState([]);
    const [outboxData, setOutboxData] = useState([]);
    const [showingLabel, setShowingLabel] = useState('inbox');
    const [viewinboxData, setViewInboxData] = useState([]);
    const [showReplyForm, setShowReplyForm] = useState(false);
    const [sending, setSending] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchInbox();
    }, []);

    const fetchInbox = () => {
        const payload = new FormData();

        payload.append('id', id);

        sendRequest(`mailbox/google/fetch-inbox`, 'POST', payload).then(response => {
            if (response.status === true) {
                setInboxData(response.data.detailedMessages);
            }
        })
    }

    // inbox menu data
    const inboxMenuData = [
        {
            id: 'uid01',
            title: 'Inbox',
            icon: 'inbox',
            // status: 3,
            label: 'inbox'
        },
        {
            id: 'uid04',
            title: 'Sent',
            icon: 'send-alt',
            label: 'outbox'
        },
    ];


    const inboxViewClasses = classNames({
        'nk-ibx-view': true,
        'show-ibx': showInbox
    });

    // toggle inbox view class
    const inboxAsideClasses = classNames({
        'nk-ibx-aside': true,
        'show-aside': sidebar,
        'toggle-collapsed': window.innerWidth < Break.xxl
    });

    // inbox view handle
    const handleInboxToggle = (id) => {

        let data;
        
        if (showingLabel == 'outbox') {
            data = outboxData.find((item) => item.id === id);
        }
        else {
            data = inboxData.find((item) => item.id === id);
        }

        setViewInboxData(data);

        setShowInbox(!showInbox);
    }

    // inbox view handle hide
    const handleInboxHide = () => {
        setViewInboxData(null);
        setShowInbox(false);
    }

    // inbox view handle hide
    const handleAsideToggle = () => {
        setSidebar(!sidebar);
    }

    const handleReplyToggle = () => {
        setShowReplyForm(!showReplyForm);
    }

    const sendMail = () => {

        setSending(true);

        const content = quill.root.innerHTML;

        const payload = new FormData();

        payload.append('mailbox_id', id);
        payload.append('thread_id', viewinboxData.threadId);
        const fromEmail = viewinboxData['from'].match(/<([^>]+)>/);
        payload.append('to_email', fromEmail ? fromEmail[1] : viewinboxData.from);
        payload.append('subject', viewinboxData.subject);
        payload.append('message_id', viewinboxData.id);
        payload.append('content', content);

        // send the mail
        sendRequest(`mailbox/google/send-mail`, 'POST', payload).then(response => {
            if (response.status === true) {
                // just empty the form
                quill.setContents([]);
                // handleReplyToggle();
                // setShowInbox(true);
                setSending(false);
            }
        })
            .catch(error => {
                setSending(false);
                console.error('POST request error:', error);
            });
    }

    const handleLabelChange = (label) => {
        setLoading(true);

        if (label === 'outbox') {
            fetchOutbox();
        }

        setShowingLabel(label);

        setShowInbox(false);

        setLoading(false);
    }

    const fetchOutbox = () => {
        const payload = new FormData();

        payload.append('id', id);

        sendRequest(`mailbox/google/fetch-outbox`, 'POST', payload).then(response => {
            if (response.status === true) {
                setOutboxData(response.data.detailedMessages);
            }
        })
    }

    return (
        <Layout ontent="container-fluid" title="Mailbox">
            <div className="nk-ibx" data-ibx-collapse="xl" id="ibxWrap" style={{ height: '85vh' }}>
                {window.innerWidth < Break.xxl && sidebar && (
                    <div onClick={handleAsideToggle} className="nk-overlay"></div>
                )}
                <SimpleBar className={inboxAsideClasses} id="ibxAside" data-overlay>
                    <div className="nk-ibx-aside-header">
                        <span onClick={() => window.history.back()} role="button"><em className="icon ni ni-arrow-left"></em></span>
                    </div>
                    <Inbox.Menu>
                        {inboxMenuData.map((item, index) =>
                            <span onClick={() => { handleLabelChange(item.label); }}>
                                <Inbox.Menu.Item key={item.id} className={showingLabel == item.label ? "active" : ''}>
                                    <Icon name={item.icon} />
                                    <span>{item.title}</span>
                                    {item.status && <span className="nk-ibx-menu-badge">{item.status}</span>}
                                </Inbox.Menu.Item>
                            </span>
                        )}
                    </Inbox.Menu>
                </SimpleBar>
                <div className="nk-ibx-body" >
                    {/* <div className="nk-ibx-head">
                        <Inbox.Tools>
                            <Inbox.Tools.Item>
                                <button className="btn btn-md btn-zoom btn-icon">
                                    <Icon name="trash"></Icon>
                                </button>
                            </Inbox.Tools.Item>

                        </Inbox.Tools>
                    </div> */}
                    {
                        showingLabel === 'inbox' && inboxData && inboxData.length > 0 ? (
                            <Inbox>
                                {inboxData.map((item) =>
                                    <Inbox.Item key={item.id} onClick={() => handleInboxToggle(item.id)}>
                                        {/* <Inbox.Item.Elem className="nk-ibx-item-check">
                                            <Form.Check className="form-check" id={`ibx-${item.id}`} />
                                        </Inbox.Item.Elem> */}
                                        <Inbox.Item.Elem className="nk-ibx-item-user">
                                            <MediaGroup>
                                                <Media size="md" shape="circle" middle variant={'info'}>
                                                    {
                                                        <span>{toFirstCapital(item.from)}</span>
                                                    }
                                                </Media>
                                                <MediaText>
                                                    <span className="title">{item.name}</span>
                                                    <span className="text smaller text-break">{item.from}</span>
                                                </MediaText>
                                            </MediaGroup>
                                        </Inbox.Item.Elem>
                                        <Inbox.Item.Elem className="nk-ibx-item-content">
                                            <div className="d-flex flex-column">
                                                <div className="nk-ibx-item-title">
                                                    <div className="nk-ibx-item-subject">{item.subject}</div>
                                                </div>
                                                <div className="nk-ibx-item-message">{item.description}</div>
                                            </div>
                                        </Inbox.Item.Elem>
                                        <Inbox.Item.Elem className="nk-ibx-item-meta">
                                            {/* <div className="time">{item.time}</div> */}
                                        </Inbox.Item.Elem>
                                    </Inbox.Item>
                                )}
                            </Inbox>
                        ) : (
                            <div className="nk-ibx-empty text-center text-muted ">
                                {showingLabel === 'inbox' && inboxData.length === 0 && <span className="text-danger">No data found</span>}
                                {loading && showingLabel === 'inbox' && <span>Loading...</span>}
                            </div>
                        )
                    }
                    <div className={inboxViewClasses} id="ibxView">
                        <div className="nk-ibx-head">
                            <Inbox.Tools>
                                <Inbox.Tools.Item>
                                    <button onClick={handleInboxHide} className="btn btn-md btn-zoom btn-icon toggle-ibx-view">
                                        <Icon name="arrow-left"></Icon>
                                    </button>
                                </Inbox.Tools.Item>
                            </Inbox.Tools>
                        </div>
                        <SimpleBar className="nk-ibx-reply nk-reply">
                            <Reply.Head subject={viewinboxData?.subject} />
                            <div className="nk-ibx-reply-group">
                                <Reply>
                                    <Reply.Header onClick={handleReplyToggle}>
                                        <MediaGroup className="nk-reply-desc">
                                            <Media size="md" shape="circle" middle>
                                                <Image src="/images/avatar/a.jpg" alt="" />
                                            </Media>
                                            <MediaText className="nk-reply-info">
                                                <div className="nk-reply-author lead-text">{viewinboxData?.from} <span className="date d-sm-none">14 Jan, 2020</span></div>
                                                <Dropdown className="nk-reply-msg-info">
                                                    <Dropdown.Toggle as={CustomDropdownToggle} className="dropdown-toggle sub-text dropdown-indicator align-items-center">
                                                        to {viewinboxData?.to}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="dropdown-menu-md" as={CustomDropdownMenu} align="end">
                                                        <ul className="nk-reply-msg-meta">
                                                            <li><span className="label">from:</span> <span className="info"><a href="javascript:void(0)">{viewinboxData?.from}</a></span></li>
                                                            <li><span className="label">to:</span> <span className="info"><a href="javascript:void(0)">{viewinboxData?.to}</a></span></li>
                                                            {/* <li><span className="label">bcc:</span> <span className="info"><a href="javascript:void(0)">team@softnio.com</a></span></li> */}
                                                            {/* <li><span className="label">mailed-by:</span> <span className="info"><a href="javascript:void(0)">softnio.com</a></span></li> */}
                                                        </ul>
                                                    </Dropdown.Menu>
                                                </Dropdown>

                                            </MediaText>
                                        </MediaGroup>
                                    </Reply.Header>
                                    <Reply.Body>
                                        <div dangerouslySetInnerHTML={{ __html: viewinboxData?.htmlContent }} />
                                    </Reply.Body>
                                </Reply>
                            </div>

                        </SimpleBar>
                    </div>
                    {
                        showingLabel === 'outbox' && outboxData && outboxData.length > 0 ? (
                            <Inbox>
                                {outboxData.map((item) =>
                                    <Inbox.Item key={item.id} onClick={() => handleInboxToggle(item.id)}>
                                        {/* <Inbox.Item.Elem className="nk-ibx-item-check">
                                            <Form.Check className="form-check" id={`ibx-${item.id}`} />
                                        </Inbox.Item.Elem> */}
                                        <Inbox.Item.Elem className="nk-ibx-item-user">
                                            <MediaGroup>
                                                <Media size="md" shape="circle" middle variant={'info'}>
                                                    {
                                                        <span>{toFirstCapital(item.to)}</span>
                                                    }
                                                </Media>
                                                <MediaText>
                                                    {/* <span className="title">{item.name}</span> */}
                                                    <span className="text smaller text-break"><b>To: </b> {item.to}</span>
                                                </MediaText>
                                            </MediaGroup>
                                        </Inbox.Item.Elem>
                                        <Inbox.Item.Elem className="nk-ibx-item-content">
                                            <div className="d-flex flex-column">
                                                <div className="nk-ibx-item-title">
                                                    <div className="nk-ibx-item-subject">{item.subject} OUTBOX</div>
                                                </div>
                                                <div className="nk-ibx-item-message">{item.description}</div>
                                            </div>
                                        </Inbox.Item.Elem>
                                        <Inbox.Item.Elem className="nk-ibx-item-meta">
                                            {/* <div className="time">{item.time}</div> */}
                                        </Inbox.Item.Elem>
                                    </Inbox.Item>
                                )}
                            </Inbox>
                        ) : (
                            <div className="nk-ibx-empty text-center text-muted ">
                                {showingLabel === 'outbox' && outboxData.length === 0 && <span className="text-danger">No data found</span>}
                                {loading && showingLabel === 'outbox' && <span>Loading...</span>}
                            </div>
                        )
                    }

                </div>

            </div>
        </Layout>
    )
}

export default Mailbox;