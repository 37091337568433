import { useEffect, useState } from 'react';
import Layout from '../../layout/default';
import FullPageLayout from '../../layout/fullpage';

import { Card, Col, Form, Nav, Row, Tab, Spinner, Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { Block, Icon } from '../../components';
import { sendRequest } from '../../utilities/api';
import { capitalizeFirstLetter } from '../../utilities';
import Swal from 'sweetalert2';
import { HostUrl } from '../../utilities/config';
import { useLocation } from 'react-router-dom';

function Email({ fullPageView = false, getUserDetail }) {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const tabValue = queryParams.get('tab');

    const [data, setData] = useState({});
    const [sendgridData, setSendgridData] = useState({});
    const [smtpData, setSMTPData] = useState({});
    const [gmailData, setGmailData] = useState({});
    const [outlookData, setOutlookData] = useState({});

    const [tab, setTab] = useState(tabValue ? tabValue : "sendgrid");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchMeta(tab);
    }, [tab]);

    const fetchMeta = (tab) => {
        setLoading(false);
        sendRequest(`email/setting/${tab}`, 'POST', {}).then(response => {
            if (response.status === true) {
                setLoading(true);
                if (tab === 'sendgrid') {
                    setSendgridData(response.data.record);
                }
                if (tab === 'smtp') {
                    setSMTPData(response.data.record);
                }
                if (tab === 'gmail') {
                    setGmailData(response.data.record);
                }
                if (tab === 'outlook') {
                    setOutlookData(response.data.record);
                }
            }
        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        let selectedItem = {};

        if (tab === 'sendgrid') {
            selectedItem = sendgridData;
        }

        if (tab === 'smtp') {
            selectedItem = smtpData;
        }

        if (tab === 'gmail') {
            selectedItem = gmailData;
        }

        sendRequest(`email/setting/${tab}/update/${selectedItem && selectedItem.id ? selectedItem.id : 0}`, 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    if (fullPageView) {
                        getUserDetail();
                        return;
                    }

                    fetchMeta(tab);
                    Swal.fire(response.message)
                }
            })
            .catch(error => {
                console.error('POST request error:', error);
            });
    };

    const setAsDefault = (event) => {
        event.preventDefault();

        const submitData = new FormData();

        let selectedItem = {};

        if (tab === 'sendgrid') {
            selectedItem = sendgridData;
        }

        if (tab === 'smtp') {
            selectedItem = smtpData;
        }

        if (tab === 'gmail') {
            selectedItem = gmailData;
        }

        if (tab === 'outlook') {
            selectedItem = outlookData;
        }

        if (selectedItem && selectedItem.status != 'Current') {
            Swal.fire({
                title: 'Are you sure?',
                text: `You want to set ${capitalizeFirstLetter(tab)} as default service?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, change it!'
            }).then((result) => {
                if (result.value) {
                    sendRequest(`email/setting/${tab}/update/${selectedItem && selectedItem.id ? selectedItem.id : 0}?action=default_set`, 'POST', submitData)
                        .then(response => {
                            if (response.status === true) {
                                Swal.fire(response.message).then(() => {
                                    if (tab === 'sendgrid') {
                                        setSendgridData(response.data.record);
                                    }

                                    if (tab === 'smtp') {
                                        setSMTPData(response.data.record);
                                    }

                                    if (tab === 'gmail') {
                                        setGmailData(response.data.record);
                                    }

                                    if (tab === 'outlook') {
                                        setOutlookData(response.data.record);
                                    }
                                })
                            }
                        })
                        .catch(error => {
                            console.error('POST request error:', error);
                        });

                }
            })
        }
    }

    const disconnect = (event) => {
        event.preventDefault();

        if (tab === 'gmail') {
            var selectedItem = gmailData;
        }

        if (tab === 'outlook') {
            var selectedItem = outlookData;
        }


        Swal.fire({
            title: 'Are you sure?',
            text: `You want to disconnect ${capitalizeFirstLetter(tab)}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes!'
        }).then((result) => {
            if (result.value) {
                sendRequest(`email/setting/${tab}/destroy/${selectedItem && selectedItem.id ? selectedItem.id : 0}`, 'POST')
                    .then(response => {
                        if (response.status === true) {
                            Swal.fire(response.message).then(() => {
                                if (tab === 'sendgrid') {
                                    setSendgridData(response.data.record);
                                }

                                if (tab === 'smtp') {
                                    setSMTPData(response.data.record);
                                }

                                if (tab === 'gmail') {
                                    setGmailData(response.data.record);
                                }

                                if (tab === 'outlook') {
                                    setOutlookData(response.data.record);
                                }
                            })
                        }
                        else {
                            Swal.fire(response.msg)
                        }
                    })
                    .catch(error => {
                        console.error('POST request error:', error);
                    });

            }
        })
    }

    const buttonStyle = {
        backgroundColor: '#ffffff',  // White background
        border: '1px solid #dcdcdc', // Light gray border
        borderRadius: '4px',         // Slightly rounded corners
        padding: '8px 16px',         // Padding for size
        cursor: 'pointer',           // Pointer cursor on hover
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Subtle shadow
        display: 'inline-block',     // Keep it inline
        textDecoration: 'none',      // No underline on link
        color: '#4285f4',            // Google Blue color for text
        fontWeight: '500',           // Medium font weight
        fontSize: '14px',            // Font size
        transition: 'background-color 0.3s, box-shadow 0.3s', // Smooth transitions
    };

    const linkStyle = {
        color: '#4285f4',            // Google Blue color
        textDecoration: 'none',      // Remove underline from link
    };

    const settingTabsUI = () => {
        return <div className="emailSetting">
            <Block>
                <Card>
                    <Card.Body>
                        <Tab.Container onSelect={(e) => setTab(e)} id="custom-tabs-example" defaultActiveKey="sendgrid">
                            <Nav variant="tabs" className="nav-tabs-s1 mb-3">
                                <Nav.Item>
                                    <Nav.Link eventKey="sendgrid">Sendgrid</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="smtp">SMTP</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="gmail">Gmail</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="outlook">Outlook</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            {
                                !loading && (
                                    <div className="d-flex justify-content-center my-5">
                                        <Spinner animation="border" variant="secondary"></Spinner>
                                    </div>
                                )
                            }
                            <Tab.Content>
                                <Tab.Pane eventKey="sendgrid">
                                    <Row>
                                        <Col lg={6}>
                                            <Form onSubmit={handleSubmit}>
                                                <Row className="g-3">
                                                    <Col lg={6}>
                                                        <Form.Group className="form-group">
                                                            <Form.Label>From Name</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control defaultValue={sendgridData?.from_name} type="text" name="from_name" placeholder="Name" onChange={(e) => setSendgridData({ ...sendgridData, from_name: e.target.value })} required />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col lg={6}>
                                                        <Form.Group className="form-group">
                                                            <Form.Label>From Email</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control defaultValue={sendgridData?.from_email} type="text" name="from_email" placeholder="Email" onChange={(e) => setSendgridData({ ...sendgridData, from_email: e.target.value })} required />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col lg={12}>
                                                        <Form.Group className="form-group">
                                                            <Form.Label>Api Key</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control defaultValue={sendgridData?.api_key} type="password" name="api_key" placeholder="***************" onChange={(e) => setSendgridData({ ...sendgridData, api_key: e.target.value })} required />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>

                                                    {sendgridData && (
                                                        <Col lg={12}>
                                                            <label>Is Default: &nbsp;</label>
                                                            <span>
                                                                {sendgridData?.status === 'Current' ? 'Yes' : 'No'}
                                                            </span>
                                                        </Col>
                                                    )}

                                                    {/* <Col lg={6}>
                                                            <Form.Group className="form-group">
                                                                <Form.Label>To Email</Form.Label>
                                                                <div className="form-control-wrap">
                                                                    <Form.Control defaultValue={sendgridData?.to_email} type="text" name="to_email" placeholder="Email" onChange={(e) => setSendgridData({ ...sendgridData, to_email: e.target.value })} required />
                                                                </div>
                                                            </Form.Group>
                                                        </Col> */}

                                                    {
                                                        sendgridData ? (
                                                            <Col className="col-12 d-flex justify-content-start">
                                                                {sendgridData.status !== 'Current' && (
                                                                    <Button variant="warning" type="button" className="btn-sm me-2" onClick={setAsDefault}>Set as default service</Button>
                                                                )}
                                                                <Button type="submit" className="btn-sm">Update</Button>
                                                            </Col>
                                                        ) : (
                                                            <Col className="col-12">
                                                                <Button variant="primary" type="submit" className="btn-sm">Create</Button>
                                                            </Col>
                                                        )
                                                    }

                                                </Row>
                                            </Form>
                                        </Col>
                                        <Col lg={6}>
                                            <button className="btn btn-color-primary btn-icon">
                                                <Icon name="help"></Icon>
                                                <span>Help</span>
                                            </button>

                                            <div className="">
                                                <p className="mb-1">Signin to your <a target='_blank' href="https://app.sendgrid.com/login">account</a></p>
                                                <p className="mb-1"><a target='_blank' href="https://app.sendgrid.com/settings/api_keys">Create API KEY</a> and assign permissions</p>
                                                <p className="mb-1">Don't forget to save your API key.</p>
                                                <p className="mb-1">Add: <OverlayTrigger trigger="click" placement="top" overlay={
                                                    <Popover id="popover-positioned-top">
                                                        <Popover.Header as="h3">Webhook Url</Popover.Header>
                                                        <Popover.Body>
                                                            {`${HostUrl}/webhook/mail/statistics`}
                                                        </Popover.Body>
                                                    </Popover>
                                                }>
                                                    <Button className='p-0' variant="link">This url </Button>
                                                </OverlayTrigger> in your
                                                    <a target='_blank' href='https://app.sendgrid.com/settings/mail_settings'> SendGrid Account Webhook</a></p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="smtp">
                                    <Row>
                                        <Col lg={6}>
                                            <Form onSubmit={handleSubmit}>
                                                <Row className="g-3">
                                                    <Col lg={12}>
                                                        <Form.Group className="form-group">
                                                            <Form.Label>SMTP Server</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control type="text" name="smtp_server" defaultValue={smtpData?.smtp_server} placeholder="smtp.gmail.com, smtp.mail.yahoo.com, or custom SMTP server" required />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col lg={6}>
                                                        <Form.Group className="form-group">
                                                            <Form.Label>From Name</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control type="text" name="from_name" defaultValue={smtpData?.from_name} placeholder="Name" required />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col lg={6}>
                                                        <Form.Group className="form-group">
                                                            <Form.Label>From Email</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control type="email" name="from_email" defaultValue={smtpData?.from_email} placeholder="Email" required />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col lg={6}>
                                                        <Form.Group className="form-group">
                                                            <Form.Label>SMTP Username</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control type="text" name="smtp_username" defaultValue={smtpData?.smtp_username} placeholder="SMTP Username" required />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col lg={6}>
                                                        <Form.Group className="form-group">
                                                            <Form.Label>SMTP Password</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control type="password" name="smtp_password" defaultValue={smtpData?.smtp_password} placeholder="********" required />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col lg={6}>
                                                        <Form.Group className="form-group">
                                                            <Form.Label>SMTP Port</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control type="number" name="smtp_port" defaultValue={smtpData?.smtp_port} placeholder="Port (e.g., 587)" required />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col lg={6}>
                                                        <Form.Group className="form-group">
                                                            <Form.Label>Security Type</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control as="select" name="security_type" defaultValue={smtpData?.security_type} required>
                                                                    <option value="">Select Security Type</option>
                                                                    <option value="TLS">TLS</option>
                                                                    <option value="SSL">SSL</option>
                                                                </Form.Control>
                                                            </div>
                                                        </Form.Group>
                                                    </Col>


                                                    {smtpData && (
                                                        <Col lg={12}>
                                                            <label>Is Default: &nbsp;</label>
                                                            <span>
                                                                {smtpData?.status === 'Current' ? 'Yes' : 'No'}
                                                            </span>
                                                        </Col>
                                                    )}

                                                    {
                                                        smtpData ? (
                                                            <Col className="col-12 d-flex justify-content-start">
                                                                {smtpData.status !== 'Current' && (
                                                                    <Button variant="warning" type="submit" className="btn-sm me-2" onClick={setAsDefault}>Set as default service</Button>
                                                                )}
                                                                <Button type="submit" className="btn-sm">Update</Button>
                                                            </Col>
                                                        ) : (
                                                            <Col className="col-12">
                                                                <Button variant="primary" type="submit" className="btn-sm">Create</Button>
                                                            </Col>
                                                        )
                                                    }

                                                </Row>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="gmail">
                                    <Row>
                                        <Col lg={6}>

                                            <div>
                                                {gmailData ? (
                                                    <>
                                                        <div>
                                                            {[
                                                                { label: "From Name", value: gmailData.from_name },
                                                                { label: "From Email", value: gmailData.from_email },
                                                                { label: "Added At", value: new Date(gmailData.created_at).toLocaleDateString() },
                                                                { label: "Is Default", value: gmailData?.status === 'Current' ? 'Yes' : 'No' },
                                                            ].map((item, index) => (
                                                                <div className="mb-2" key={index}>
                                                                    <span className="fw-bold">{item.label}: </span>
                                                                    <span>{item.value}</span>
                                                                    <br />
                                                                </div>
                                                            ))}
                                                        </div>

                                                        <Col className="col-12">
                                                            {gmailData.status !== 'Current' && (
                                                                <Button variant="warning" type="submit" className="btn-sm me-2" onClick={setAsDefault}>Set as default service</Button>
                                                            )}
                                                            <Button variant="danger" type="button" className="btn-sm" onClick={disconnect}>Disconnect Gmail API</Button>
                                                        </Col>

                                                    </>
                                                ) : (
                                                    <button style={buttonStyle}>
                                                        <a href="#" style={linkStyle} onClick={async (e) => {
                                                            e.preventDefault();
                                                            try {
                                                                const response = await sendRequest('gmail/consent-screen');
                                                                window.location.href = response.consent_screen_link;
                                                            } catch (error) {
                                                                console.error('Error fetching authorization URL:', error);
                                                            }
                                                        }}>
                                                            Configure Gmail API
                                                        </a>
                                                    </button>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="outlook">
                                    <Row>
                                        <Col lg={6}>

                                            <div>
                                                {outlookData ? (
                                                    <>
                                                        <div>
                                                            {[
                                                                { label: "From Name", value: outlookData.from_name },
                                                                { label: "From Email", value: outlookData.from_email },
                                                                { label: "Added At", value: new Date(outlookData.created_at).toLocaleDateString() },
                                                                { label: "Is Default", value: outlookData?.status === 'Current' ? 'Yes' : 'No' },
                                                            ].map((item, index) => (
                                                                <div className="mb-2" key={index}>
                                                                    <span className="fw-bold">{item.label}: </span>
                                                                    <span>{item.value}</span>
                                                                    <br />
                                                                </div>
                                                            ))}
                                                        </div>

                                                        <Col className="col-12">
                                                            {outlookData.status !== 'Current' && (
                                                                <Button variant="warning" type="submit" className="btn-sm me-2" onClick={setAsDefault}>Set as default service</Button>
                                                            )}
                                                            <Button variant="danger" type="button" className="btn-sm" onClick={disconnect}>Disconnect Outlook API</Button>
                                                        </Col>
                                                    </>
                                                ) : (
                                                    <button style={buttonStyle}>
                                                        <a href="#" style={linkStyle} onClick={async (e) => {
                                                            e.preventDefault();
                                                            try {
                                                                const response = await sendRequest('outlook/consent-screen');
                                                                window.location.href = response.consent_screen_link;
                                                            } catch (error) {
                                                                console.error('Error fetching authorization URL:', error);
                                                            }
                                                        }}>
                                                            Configure Outlook API
                                                        </a>
                                                    </button>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </Card.Body>
                </Card>
            </Block>
        </div>
    }

    return (
        <>
            {fullPageView === true ? (
                <FullPageLayout title="Email Setting" content="container-fluid" nkWrap={false}>
                    {settingTabsUI()}
                </FullPageLayout>
            ) : (
                <Layout title="Email Setting" content="container-fluid">
                    {settingTabsUI()}
                </Layout>
            )}
        </>

    )
}

export default Email;
