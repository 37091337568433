import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form, Spinner, Modal, ButtonGroup, Alert, ListGroup } from 'react-bootstrap';

import Layout from '../../../layout/fullpage';
import Block from '../../../components/Block/Block';

import { Image, Icon, MediaAction, MediaGroup, MediaText, Media, Select, TimeZone, TimePicker } from '../../../components';
import { sendRequest } from "../../../utilities/api";
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'

import bootstrapPlugin from '@fullcalendar/bootstrap'
import { today, uInfo } from "../../../utilities";
import { countries } from "../../../store/customData";
import BasicLeadCreate from "../../../pages/crm-manage/leads/BasicLeadCreate";
import { use } from "react";

function useQuery() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}

function AppointmentCreate(props) {
	let query = useQuery();
	const { loc_id } = useParams();
	const [cal_id, setCal_id ] = useState(props.cal_id ?? null);
	const [lead_id, setLead_id ] = useState(props.lead_id ?? null);
	const [lead_name, setLead_name ] = useState(props.lead_name ?? null);
	const [loading, setLoading] = useState(false);
	const [meta, setMeta] = useState({ slots: [], calendar: {} });
	const [data, setData] = useState({ contact_type: uInfo().id ? 'existing' : 'new', lead_id: 0, lead_name: '' });
	const [main, setMain] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone ? Intl.DateTimeFormat().resolvedOptions().timeZone.split('/').shift() : 'America');
	const [zone, setZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone ? Intl.DateTimeFormat().resolvedOptions().timeZone : 'America/Los_Angeles');
	const [modal, setModal] = useState(false);

	const [disableDay, setDiableDay] = useState([]);
	const [slots, setSlots] = useState([]);
	const [slotLoader, setSlotLoader] = useState(false);
	const [selectedSlot, setSelectedSlot] = useState({});
	const [existingLead, setExistingLead] = useState([]);
	const [isLead, setIsLead] = useState(true);
	const { type, selectedDate: propsSelectedDate, selectedTime: propsSelectedTime } = props;
	const [next, setNext] = useState(type === 'selected_date' ? true : false);
	const [selectedDate, setSelectedDate] = useState(propsSelectedDate ?? today);
	const [selectedTime, setSelectedTime] = useState(propsSelectedTime ?? '');
	const [selectedFormatedDate, setFormatedSelectedDate] = useState(propsSelectedDate ?? today);
	const [fromPage] = useState(type === 'selected_date' ? 'selected_date' : 'default');
	const [pageName] = useState(props.pageName ? props.pageName : 'calendar');
	// const [pageName] = useState(query.has('page_name') ? query.get('page_name') : 'calendar');
	const [currentType, setCurrentType] = useState('0');
	const [appointmentTypes, setAppointmentTypes] = useState([]);
    const { closeEventModal } = props;

	const fetchMeta = () => {
		const formData = new FormData();

		formData.append('calendar_id', cal_id);
		formData.append('loc_id', loc_id);
		formData.append('time_zone', zone);
		formData.append('date', selectedDate);
		formData.append('time', selectedTime);
		formData.append('page_name', pageName);

		sendRequest('calendar/appointments/slots/fetch', 'POST', formData).then(response => {
			if (response.status === true) {
				setMeta(response.data);
				setAppointmentTypes(response.data.appointmentTypes);
				setFormatedSelectedDate(response.data.clientFormattedDate) // here is the place the date selected by client in the calendar showing here, need to do same for the selected time
				setDiableDay(response.data.offDays);
				setSlots(response.data);
				setSelectedSlot({});
				setLoading(true);
				setSlotLoader(true);
				setModal(false);
				if (lead_id) {
					setData({ ...data, lead_id: lead_id, lead_name: `${lead_id} # ${lead_name}` });
				}
			}
		})
			.catch(error => {
				setLoading(true);
				setModal(false);
				setSlotLoader(true);
				console.error('POST request error:', error);
			});
	}

	useEffect(() => {
		fetchMeta();
		setSlotLoader(false);
	}, [zone, selectedDate]);

	const handleEventModalClose = () => {
        closeEventModal(); 
    };

	const handleSubmit = async (event) => {
		event.preventDefault();
		const formData = new FormData(event.currentTarget);
		const submitData = new FormData();
		for (let [key, value] of formData.entries()) {
			submitData.append(key, value);
		}

		submitData.append('calendar_id', cal_id);
		submitData.append('loc_id', loc_id);
		submitData.append('time_zone', zone);
		submitData.append('lead_id', data.lead_id);
		submitData.append('page_name', pageName);


		sendRequest('calendar/appointments/create', 'POST', submitData)
			.then(response => {
				if (response.status === true) {
					if (pageName == 'calendar') {
						window.parent.location.reload();
					}
					else {
						handleEventModalClose();
					}
					
					// if (meta.calendar.redirect_url != '') {
					// 	window.location.href = meta.calendar.redirect_url;
					// } else {
					// 	// if (fromPage === 'default') {
					// 	// 	Swal.fire({
					// 	// 		icon: 'success',
					// 	// 		title: meta.calendar.thankyou_msg
					// 	// 	});
					// 	// } else {

					// 	// }

					// 	// Swal.fire({
					// 	// 	icon: 'success',
					// 	// 	title: meta.calendar.thankyou_msg,
					// 	// }).then((result) => {
					// 	// 	if (fromPage === 'default') {
					// 	// 		window.location.reload();
					// 	// 	} else {
					// 	// 		window.parent.postMessage('iframeLoaded', '*');
					// 	// 	}
					// 	// });

					// 	window.parent.postMessage('iframeLoaded', '*');
					// 	if (fromPage === 'default') {
					// 		window.location.reload();
					// 	}

					// }
				}
			})
			.catch(error => {
				console.error('POST request error:', error);
			});
	};

	const handleZone = async (event) => {
		event.preventDefault();
		const formData = new FormData(event.currentTarget);
		for (let [key, value] of formData.entries()) {
			if (key === 'main_zone') {
				setMain(value);
			}

			if (key === 'time_zone') {
				setZone(value);
			}
		}

	};

	const handleDateClick = (arg) => {

		const currentDate = new Date();
		currentDate.setHours(0, 0, 0, 0); // Set time to midnight

		const argDate = new Date(arg.date);
		argDate.setHours(0, 0, 0, 0);

		if (disableDay.includes(arg.date.getDay()) || argDate < currentDate) {
			// console.log("reasda sasd  ", arg.date, currentDate)
			return;
		}
		setSelectedDate(arg.dateStr);
	};

	const dayCellClassNames = (arg) => {
		const classNames = [];
		if (disableDay.includes(arg.date.getDay())) {
			classNames.push('disabled-cell');
		}

		if (!arg.date || !(arg.date instanceof Date)) {
			throw new Error('Invalid date object');
		}

		let date = arg.date.getFullYear() +
			'-' +
			('0' + (arg.date.getMonth() + 1)).slice(-2) +
			'-' +
			('0' + arg.date.getDate()).slice(-2);

		if (selectedDate && date === selectedDate) {
			classNames.push('selected-cell');
		}

		return classNames.join(' ');
	};

	const dayCellContent = (arg) => {
		try {
			if (!arg.date || !(arg.date instanceof Date)) {
				throw new Error('Invalid date object');
			}

			let date = arg.date.getFullYear() +
				'-' +
				('0' + (arg.date.getMonth() + 1)).slice(-2) +
				'-' +
				('0' + arg.date.getDate()).slice(-2);

			if (typeof selectedDate !== 'string') {
				throw new Error('Invalid selectedDate');
			}

			const isSelected = selectedDate && date === selectedDate;

			const selectedClass = isSelected ? 'selected-date' : '';

			return (
				<div className={`fc-daygrid-day-number ${selectedClass}`}>
					{arg.dayNumberText}
				</div>
			);
		} catch (error) {
			return (
				<div className="fc-daygrid-day-number">
					{arg.dayNumberText}
				</div>
			);
		}
	};
	const searchLead = (search) => {
		setData({ ...data, lead_id: 0, lead_name: `${search}` });
		if (search != "") {
			const formData = new FormData();
			formData.append('filter_value', search);
			formData.append('type', 'search');
			sendRequest(`crm/leads/lists`, 'POST', formData).then(response => {
				if (response.status === true) {
					setExistingLead(response.data.records);
					setIsLead(response.data.records.length > 0 ? true : false);
					//console.log(response.data.records.length, 'dddddddd')
					if (response.data.records.length < 1) {
						//setData({ ...data, lead_id: 0 });
					}
				}
			})
				.catch(error => {
					console.error('POST request error:', error);
				});
		} else {
			setExistingLead([]);
			setIsLead(false);
		}
	};

	const sendMessageToParent = (calendar_id) => {
		if (calendar_id != '') {
			const res = {
				type: 'changeUrl',
				url: calendar_id,
				lead_id: data.lead_id,
				lead_name: query.get('lead_name')
			};
			window.parent.postMessage(res, '*');
		}
	}
	return (
		<Layout title="Calendar" content="container-fluid">
			<div className={`e-appointment-create container-fluid my-2 ${pageName === 'contact' ? 'iframeArea' : ''}`}>
				<Card>
					{
						!loading ? (
							<Spinner animation="grow" variant="primary"></Spinner>
						) : (
							<Card.Body className="p-0">
								<header className={`py-2 mb-1 px-4 d-flex AppointmentHeader justify-content-between align-items-center ${fromPage != 'default' && ('d-none')}`} style={{ backgroundColor: meta.calendar.header_bg_color }}>
									<div className="d-flex">
										<div className="AppoinmentLogo mx-4 ms-0">
											<img className="calendar-logo" src={meta.calendar.media_file_url} alt={meta.calendar.title} />
										</div>
										<div>
											<h2 className="mb-0" style={{ color: meta.calendar.title_color }}>{meta.calendar.title}</h2>
											<p style={{ color: meta.calendar.sub_title_color }}>{meta.calendar.sub_title}</p>
										</div>
									</div>
									{
										pageName === 'contact' && (

											<Form.Group className="form-group w-25">
												{/* <Form.Label htmlFor="Calendar">Calendar</Form.Label> */}
												<div className="form-control-wrap">
													<Form.Select defaultValue={cal_id} size="sm" onChange={(e) => sendMessageToParent(e.target.value)}>
														<option value={''}>Select Calander</option>
														{
															slots.calendars && slots.calendars.map((row, i) => {
																return <option key={i} value={row.public_id}>{row.title}</option>
															})
														}
													</Form.Select>
												</div>
											</Form.Group>

										)
									}
								</header>
								{
									!next && (
										<div className="px-3 bio-block">
											<Row>



												<Col md="6" sm="6">

													<FullCalendar
														plugins={[dayGridPlugin, interactionPlugin]}
														initialView="dayGridMonth"
														dateClick={handleDateClick}
														initialDate={selectedDate}
														dayCellClassNames={dayCellClassNames}
														dayCellContent={dayCellContent}
													/>
													<Alert variant="warning " className="mt-3">
														<span className="d-flex align-items-center"><p className="m-0"> All times are shown in the <Alert.Link>{zone} </Alert.Link> zone.</p> <Button size="sm" onClick={() => setModal(true)} className="mx-2 btn btn-info">Change Zone</Button>
														</span>
													</Alert>
												</Col>
												<Col md="6" sm="6">
													{
														!slotLoader ? (
															<Alert className="d-flex">
																<Icon name="loader" className="me-1 mt-1"></Icon>
																<div> Please Wait... </div>
															</Alert>
														) :
															(
																<Row className="slots g-3">
																	<label> Availability for {meta.formattedDate}</label>
																	<div class="time-slots-container">

																		{
																			slots.slots.map((row, i) => {
																				return (
																					<div class="time-slot">
																						<input defaultChecked={row.value == selectedSlot.value} defaultValue={row.value} onClick={(e) => setSelectedSlot(row)} type="radio" className="btn-check" name="btnradio" id={`btnradio${i}`} autoComplete="off" />
																						<Button className="btn-sm btn-md" as="label" variant="outline-primary" htmlFor={`btnradio${i}`}>{row.value}</Button>
																					</div>
																					// <Col key={i}  md="3" sm="4" className="slot">
																					// </Col>
																				);
																			})
																		}
																	</div>

																	{
																		(selectedSlot.value && selectedSlot.value !== '') && (
																			<div className="col-lg-12">
																				<Alert variant="secondary">
																					<div className="d-flex justify-content-between align-items-center">
																						<span> Appointment on <Alert.Link>{selectedFormatedDate} {selectedSlot.value} &nbsp;</Alert.Link> <Button size="sm" onClick={() => setNext(true)}>Confirm</Button>
																						</span>
																						<span><Icon name="clock" className="me-1 mt-1"></Icon> <Alert.Link>{meta.calendar.appointment_duration} minutes</Alert.Link></span>
																					</div>
																				</Alert>
																			</div>
																		)
																	}

																</Row>

															)
													}
												</Col>
											</Row>


										</div>
									)
								}
							</Card.Body>
						)
					}

					{
						next && (
							<Card.Body className="p-0 pb-3">
								<div className="px-3 bio-block">
									<Form onSubmit={handleSubmit}>
										<Row className="mb-1">
											<Form.Control type="hidden" value={data.contact_type} name="contact_type" required />
											{(uInfo().id && data.contact_type != 'new') ? (
												<>


													<Col md="4" className="mb-1">
														<Form.Group className="form-group">
															<Form.Label htmlFor="Phone">Find Contact</Form.Label>
															<div className="form-control-wrap">
																<Form.Control type="text" size="sm" name="search_contact"
																	placeholder="Search Contact..."
																	value={data.lead_name}
																	onChange={(e) => searchLead(e.target.value)}
																	// readOnly={query.has('lead_id') && query.get('lead_id')}
																	readOnly={lead_id ? true : false}
																	onBlur={() =>
																		setTimeout(() => {
																			setExistingLead([]);
																		}, 1000)
																	}
																/>
															</div>
														</Form.Group>

														{existingLead.length > 0 && (
															<ListGroup className="my-1">
																{existingLead.map((row, i) => {
																	return (
																		<ListGroup.Item key={i} onClick={() => setData({ ...data, lead_id: row.id, lead_name: `${row.id}# ${row.first_name} ${row.last_name}` })} >
																			<MediaGroup>
																				<MediaText>
																					<div className="lead-text">{row.first_name} {row.last_name}</div>
																					<span className="sub-text">{row.email}</span>
																				</MediaText>
																			</MediaGroup>
																		</ListGroup.Item>
																	);
																})}
															</ListGroup>
														)}
													</Col>
													<Col md="4" className="mb-1">
														<Form.Group className={`form-group ${lead_id && ('d-none')}`}>
															<Form.Label htmlFor="Phone"></Form.Label>
															<div className="form-control-wrap my-1">
																<button type="button" onClick={() => setData({ ...data, contact_type: 'new' })} className="btn btn-sm btn-primary">Add new</button>
															</div>
														</Form.Group>
													</Col>
												</>
											) : (
												<>
													<Row>
														<Col>
															<button className="btn p-0" style={{ float: 'right' }} title="Back to existing lead" onClick={() => setData({ ...data, contact_type: 'existing' })}>
																<Icon name="back-arrow" className='text-info'></Icon>
															</button>
														</Col>
													</Row>

													<BasicLeadCreate
														id={null}
														currentType={currentType}
														setCurrentType={setCurrentType}
														coachPartners={meta?.sales_person}
													/>
												</>
											)}
										</Row>

										{
											uInfo().id && (
												<Row>
													<Col md="12" className={'mb-3'}>
														<Form.Group>
															<Form.Label htmlFor="eventTitle">Title</Form.Label>
															<div className="form-control-wrap">
																<Form.Control required defaultValue={pageName === 'contact' ? slots.lead_default_title : slots.default_title} name="title" type="text" placeholder="Title" />
															</div>
														</Form.Group>
													</Col>

													<Col md="6" className={'mb-1'}>
														<Form.Group className="form-group">
															<Form.Label htmlFor="role">Sales Person:</Form.Label>
															{slots.sales_person && slots.sales_person.length > 0 && (
																<Select defaultValue={uInfo().id} name="sales_person_id"  >
																	<option value="">Select</option>
																	{
																		slots.sales_person.map((row, i) => {
																			return <option key={i} value={row.id}> {row.first_name} {row.last_name}</option>
																		})
																	}
																</Select>
															)}
														</Form.Group>
													</Col>

													<Col md="6" className={'mb-1'}>
														<Form.Group className="form-group">
															<Form.Label htmlFor="role">Products:</Form.Label>
															{slots.products && slots.products.length > 0 && (
																<Select name="product_id" >
																	<option value="">Select</option>
																	{
																		slots.products.map((row, i) => {
																			return <option key={i} value={row.id}> {row.title}</option>
																		})
																	}
																</Select>
															)}
														</Form.Group>
													</Col>

													<Col md="6" className={'mb-1'}>
														<Form.Group className="form-group">
															<Form.Label htmlFor="role">Client Reminders:</Form.Label>
															{slots.campaigns && slots.campaigns.length > 0 && (
																<Select name="campaign_id"  >
																	<option value="">Select</option>
																	{
																		slots.campaigns.map((row, i) => {
																			return <option key={i} value={row.id}> {row.title}</option>
																		})
																	}
																</Select>
															)}
														</Form.Group>
													</Col>

													<Col md="6" className={'mb-1'}>
														<Form.Group className="form-group">
															<Form.Label htmlFor="role">User Reminders:</Form.Label>

															<Form.Select name="user_reminder" className="form-control"  >
																<option value="No">No</option>
																<option value="Yes">Yes</option>

															</Form.Select>

														</Form.Group>
													</Col>
												</Row>
											)
										}
										<Row>
											<Col md="6" className="mb-1">
												<Form.Group className="form-group">
													<Form.Label htmlFor="Phone">Date</Form.Label>
													<div className="form-control-wrap">
														<Form.Control readOnly defaultValue={selectedDate} type="date" size="sm" name="date" placeholder="Date" />
													</div>
												</Form.Group>
											</Col>

											<Col md="6" className="mb-1">

												<Form.Group className="form-group">
													<Form.Label htmlFor="time">Preferred Time</Form.Label>
													{
														fromPage === 'default' ? (
															<div className="form-control-wrap sm">
																<Form.Control readOnly defaultValue={selectedSlot.key} type="time" size="sm" name="start" placeholder="Date" />
															</div>
														) : (
															<div className="form-control-wrap">
																<Form.Select
																	name="start"
																	value={selectedTime ? selectedTime : data.start} // Bind selectedTime to the value prop
																	onChange={(e) => setSelectedTime(e.target.value)} // Update the selected time on change
																	size="sm"
																>
																	{
																		slots.slots && slots.slots.map((row, i) => {
																			return <option key={i} value={row.key}>{row.value}</option>;
																		})
																	}
																</Form.Select>

															</div>
														)
													}
												</Form.Group>
											</Col>

											<Col md="6" className="mb-1">
												<Form.Group className="form-group">
													<Form.Label htmlFor="type">Type</Form.Label>
													{
														<div className="form-control-wrap">
															<Form.Select name="type"  size="sm" >
																<option value={''}>Select Type</option>
																{
																	appointmentTypes && Object.entries(appointmentTypes).map(([key, value]) => {
																		return <option key={key} value={key}>{value}</option>
																	})
																}
															</Form.Select>
														</div>
													}
												</Form.Group>
											</Col>

											<Col md="12" className="mb-1">
												<Form.Group className="form-group">
													<Form.Label htmlFor="time">Notes</Form.Label>
													<div className="form-control-wrap">
														<Form.Control as="textarea" name="notes" placeholder="Enter note" />
													</div>
												</Form.Group>
											</Col>
											<Col className="col-12">
												<Button variant="primary" type="submit" className="btn-sm" >
													Create
												</Button>
												{
													fromPage === 'default' && (
														<Button onClick={() => { setNext(false); setData({ contact_type: 'existing' }) }} variant="secondary" type="button" className="btn-sm mx-1" >
															Cancel
														</Button>
													)
												}
											</Col>
										</Row>
									</Form>
								</div>
							</Card.Body>
						)
					}
				</Card>
			</div >
			<Modal show={modal} onHide={() => setModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						Change TimeZone
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<Form onSubmit={handleZone}>
						<Row className="g-3">
							<Col md="12">

								<TimeZone main_key="main_zone" sub_key="time_zone" mainZone={main} zone={zone} />
							</Col>

							<Col md="12">
								<Button size="sm" type="submit" >Update</Button>
							</Col>
						</Row>
					</Form>
				</Modal.Body>
			</Modal>
		</Layout >

	)
}

export default AppointmentCreate;
