import { useEffect, useState, useRef } from 'react';
import { Card, Button, Form, Row, Col, Dropdown, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
// import DataTable from 'react-data-table-component';
import { sendRequest } from '../../utilities/api';
import { Icon, CustomDropdownToggle, CustomDropdownMenu, LinkList, LinkListItem, Select } from "../../components";
import { useAuth } from '../../AuthContext';
import DataTableComponent from '../../components/DataTable/DataTable';
import CalanderNav from '../nav/CalanderNav';
import Swal from 'sweetalert2';

function CalendarList() {
	const { hasPermission } = useAuth();
	const [meta, setMeta] = useState({ locations: [] });
	const [customDateFilter, setCustomDateFilter] = useState(false);
	const [loading, setLoading] = useState(false);
	const [modal, setModal] = useState(false);
	const [data, setData] = useState({});

	const formRef = useRef(null);
	const childRef = useRef(null);


	useEffect(() => {
		fetchMeta();
	}, []);

	const fetchMeta = () => {
		const formData = new FormData();
		sendRequest('location/meta/fetch', 'POST', formData).then(response => {
			if (response.status === true) {
				setMeta(response.data);
				setLoading(true);
			}
		})
			.catch(error => {
				setLoading(true);
				console.error('POST request error:', error);
			});
	}

	const openModal = (data) => {
		setModal(true);
		setData(data);
	}

	const closeModal = (fetchRecord = false) => {
		if (fetchRecord) {
			if (childRef.current) {
				childRef.current.handleFilter(new FormData());
			}
		}
		setData({});
		setModal(false);

	}


	const handleSubmit = async (event) => {
		event.preventDefault();
		const formData = new FormData(event.currentTarget);
		if (childRef.current) {
			childRef.current.handleFilter(formData);
		}
	};

	const handleReset = () => {
		if (formRef.current) {
			formRef.current.reset();
			if (childRef.current) {
				childRef.current.handleFilter(new FormData());
			}
			setCustomDateFilter(false);
		}
	};

	const deleteRecord = async (id) => {
		Swal.fire({
			title: 'Are you sure?',
			text: `You want to delete this calendar?`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, delete it!'
		}).then((result) => {
			if (result.value) {
				sendRequest(`calendar/${id}`, 'DELETE', { id: id })
					.then(response => {
						if (response.status === true) {
							if (childRef.current) {
								childRef.current.handleFilter(new FormData());
							}
						}
					})
					.catch(error => {
						console.error('Delete request error:', error);
					});
			}
		});

	};


	const userColumns = [
		{
			name: "Title",
			key: 'title',
			selector: (row) => row.title,
			cell: (row) => (


				<span>
					{
						hasPermission('editCalendar') ? (
							<Link to={`/calendar-manage/edit/${row.public_id}`}>
								{row.title}
							</Link>
						) : (
							row.title
						)
					}

				</span>



			),
		},


		{
			name: "Sub Title",
			key: 'sub_title',
			selector: (row) => row.sub_title,
			cell: (row) => (
				<span>{row.sub_title}</span>
			),
		},
		{
			name: "Default Location",
			key: 'default_location_id',
			selector: (row) => row.default_location_id,
			cell: (row) => (
				<button onClick={() => openModal(row)} className={`badge text-bg-secondary-soft`}>
					{row.location_default?.name ?? 'NONE'}
				</button>
			),
		},
		{
			name: <div className='w-100 text-center'>Status</div>,
			key: 'status',
			selector: (row) => row.status,
			cell: (row) => (
				<span className={`badge m-auto text-bg-${row.status === "Active" ? "success-soft"
					: row.status === "Pending" ? "warning-soft"
						: row.status === "Inactive" ? "secondary-soft"
							: "primary-soft"}`
				}>
					{row.status ? row.status : 'General'}
				</span>
			),
		},
		{
			name: "Created Date",
			key: 'dated',
			selector: (row) => row.dated,
			cell: (row) => (
				<span>{row.dated}</span>
			),
		},

		{
			name: "Action",
			key: 'action',
			cell: (row) => (
				<div className="text-center w-100">
					<div className="dropdown-content">
						<LinkList className="link-list-hover-bg-primary link-list-md d-flex justify-content-center align-items-center">
							{
								hasPermission('deleteCalendar') && row.can_delete == 1 && (
									<button className="btn p-0" onClick={() => deleteRecord(row.public_id)}>
										<Icon name="trash" className='text-danger'></Icon>
									</button>
								)
							}


						</LinkList>
					</div>
				</div>
			),
		},

	];

	const chandeDefaultLoaction = (event) => {
		event.preventDefault();
		const formData = new FormData(event.currentTarget);
		const submitData = new FormData();
		for (let [key, value] of formData.entries()) {
			submitData.append(key, value);
		}

		sendRequest(`calendar/update/${data.public_id}/default/location`, 'POST', submitData)
			.then(response => {
				if (response.status === true) {
					closeModal(true);
				}
			})
			.catch(error => {
				console.error('POST request error:', error);
			});
	}

	return (
		<Layout title="Calendar List" content="container-fluid">
			<div className="locations">
				<Block.Head>
					<Block.HeadBetween>
						<Block.HeadContent>
							<Block.Title tag="h2">Calendar List</Block.Title>
							<nav>
								<ol className="breadcrumb breadcrumb-arrow mb-0">
									<li className="breadcrumb-item"><Link to="/">Home</Link></li>
									<li className="breadcrumb-item active" aria-current="page">Calendars</li>
								</ol>
							</nav>
						</Block.HeadContent>
						<Block.HeadContent>

							<ul className="d-flex">
								<CalanderNav from={1} />

								{
									hasPermission('createLocation') && (
										<li>
											<Link className='btn btn-primary d-md-inline-flex btn-sm' to="/calendar-manage/create">
												<Icon name="plus" />
												<span>Add Calendar</span>
											</Link>

										</li>
									)
								}
							</ul>
						</Block.HeadContent>
					</Block.HeadBetween>
				</Block.Head>
				<Block>
					<Card>
						<Card.Body>
							<Form ref={formRef} onSubmit={handleSubmit}>
								<Row className="g-3">
									<Col>
										<Form.Select
											size="sm"
											onChange={(e) =>
												e.target.value == "Custom Date"
													? setCustomDateFilter(true)
													: setCustomDateFilter(false)
											}
											name="date_range">
											<option value="">Filter by Date</option>
											<option value="Current Week">Current Week</option>
											<option value="Last Week">Last Week</option>
											<option value="Current Month">Current Month</option>
											<option value="Last Month">Last Month</option>
											<option value="Last 3 Months">Last 3 Months</option>
											<option value="Last 6 Months">Last 6 Months</option>
											<option value="Current Year">Current Year</option>
											<option value="Last Year">Last Year</option>
											<option value="Custom Date">Custom Date</option>
										</Form.Select>
									</Col>
									{
										customDateFilter && (
											<>
												<Col>
													<Form.Control size="sm" type="date" name="start_date" placeholder="Start date" disabled={!customDateFilter} required={customDateFilter} />
												</Col>

												<Col>
													<Form.Control size="sm" type="date" name="end_date" placeholder="End date" disabled={!customDateFilter} required={customDateFilter} />
												</Col>
											</>
										)
									}

									<Col>
										<Form.Select size="sm" name="status" >
											<option value="">All </option>
											<option value="Active">Active</option>
											<option value="Inactive">Inactive</option>
										</Form.Select>
									</Col>

									<Col>
										<Button size="sm" type="submit" className='mr-5'>Filter</Button>
										<Button size="sm" variant="outline-light" type='button' onClick={handleReset}>Reset</Button>

									</Col>
								</Row>
							</Form>

							<DataTableComponent
								childRef={childRef}
								selectableRows={false}
								columns={userColumns}
								tableClassName="data-table-head-light table-responsive"
								slug={'calendar/lists'}
							/>
						</Card.Body>
					</Card>
				</Block>

				<Modal size='md' show={modal} onHide={() => closeModal()}>
					<Form onSubmit={chandeDefaultLoaction}>
						<Modal.Header closeButton>
							<Modal.Title className="calander-location" as="h4">Change calander default location</Modal.Title>
						</Modal.Header>


						<Modal.Body >
							<Form.Group className="form-group">
								<Form.Label htmlFor="color_code">Locations </Form.Label>
								<div className="form-control-wrap">
									{meta?.locations.length > 0 && (
										<Select name="default_location_id" defaultValue={data.default_location_id}>
											<option value="">Select</option>
											{
												meta?.locations.map((row, i) => {
													return <option key={i} value={row.id}>{row.name}</option>
												})
											}
										</Select>
									)}
								</div>
							</Form.Group>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="danger" onClick={() => closeModal()}>Close</Button>
							<Button variant="primary" type="submit" >{data.public_id ? 'Update' : 'Create'}</Button>
						</Modal.Footer>
					</Form>
				</Modal>
			</div>
		</Layout>
	)
}

export default CalendarList;
