import { useEffect, useState } from "react";
import { Dropdown, Button, Card, Row, Col, Form, Spinner, Tab, Nav } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import classNames from "classnames";
import Block from '../../components/Block/Block';
import { Link, useParams } from 'react-router-dom';
import { sendRequest } from "../../utilities/api";
import Swal from "sweetalert2";
import { GOOGLE_AUTH_CLIENT_ID } from "../../store/site";
import { HostUrl } from '../../utilities/config';


import Layout from '../../layout/default';

function MailboxSettings() {
    const [meta, setMeta] = useState({ coachPartners: [] });
    const [loading, setLoading] = useState(false);
    const [emails, setEmails] = useState([]);
    const [newEmail, setNewEmail] = useState('');
    const [tab, setTab] = useState('gmail');

    useEffect(() => {
        fetchMeta();
    }, []);

    const fetchMeta = () => {
        setLoading(true);
        sendRequest(`configured/mailboxes`, 'POST', {}).then(response => {
            if (response.status === true) {
                setLoading(false);
                setEmails(response.data.mailboxes);
            }
        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }

    // Handlers for button actions
    const fetchMailbox = (email) => {
        console.log(`Syncing ${email}`);
    };

    const handleReconnect = (email) => {
        console.log(`Reconnecting ${email}`);
    };

    const handleDisconnect = (mailbox_id) => {

        Swal.fire({
            title: 'Are you sure?',
            text: `You want to disconnect ${newEmail}? This will delete it from our database and no more emails will be logged in our system.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes! Delete it'
        }).then((result) => {
            if (result.value) {
                sendRequest(`mailbox/destroy/${mailbox_id}`, 'POST', {})
                    .then(response => {
                        if (response.status === true) {
                            Swal.fire(response.data.message);
                            setEmails(emails.filter((e) => e.id !== mailbox_id)); 
                        }
                    })
                    .catch(error => {
                        console.error('POST request error:', error);
                    });
            }
        });
    };

    const handleAddEmail = () => {
        sendRequest(`mailbox/${tab}/authorize`, 'POST', { login_hint: newEmail })
            .then(response => {

                if (response.status === true) {
                    const authorizationUrl = response.data.authorization_url;

                    window.location.href = authorizationUrl;
                }
            })
            .catch(error => {
                console.error('GET request error:', error);
            });
    };

    const handleSubmit = async (event) => {
        setLoading(false);
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        sendRequest(`dropdown/tracking/code`, 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    Swal.fire(response.message)
                }
                setLoading(true);
            })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    };

    return (
        <Layout title="Mailbox Settings" content="container-fluid">
            <div className="embed-code">
                <Block.Head>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Mailbox Settings</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Configured Emails</li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                </Block.Head>
                <Card>
                    {
                        loading && (
                            <div className="d-flex justify-content-center my-5">
                                <Spinner animation="border" variant="secondary"></Spinner>
                            </div>
                        )
                    }
                    <Card.Body>
                        <Tab.Container
                            onSelect={(e) => setTab(e)}
                            id="custom-tabs-example"
                            defaultActiveKey="gmail"
                        >
                            {/* Tab Navigation */}
                            <Nav variant="tabs" className="nav-tabs-s1 mb-3">
                                <Nav.Item>
                                    <Nav.Link eventKey="gmail">Gmail</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="hotmail">Hotmail</Nav.Link>
                                </Nav.Item>
                            </Nav>

                            {/* Loading Indicator */}
                            {loading && (
                                <div className="d-flex justify-content-center my-5">
                                    <Spinner animation="border" variant="secondary"></Spinner>
                                </div>
                            )}

                            {/* Tab Content */}
                            <Tab.Content>
                                {/* Gmail Tab */}
                                <Tab.Pane eventKey="gmail">
                                    <ul className="list-group mb-4">
                                        {emails && emails
                                            .filter(email => email.mail_type === 'gmail')
                                            .map((email, index) => (
                                                <li
                                                    key={index}
                                                    className="list-group-item d-flex justify-content-between align-items-center"
                                                    style={{
                                                        backgroundColor: '#f8f9fa',
                                                        padding: '15px',
                                                        borderRadius: '8px',
                                                        marginBottom: '10px',
                                                    }}
                                                >
                                                    <span>{email.email}</span>
                                                    <div>
                                                        <Link
                                                            to={`/setting-manage/mailbox/${btoa(email.id)}`}
                                                            className="btn btn-primary btn-sm mx-1"
                                                        >
                                                            Mailbox
                                                        </Link>
                                                        <Button variant="danger" className="btn-sm mx-1" onClick={() => handleDisconnect(email.id)}>
                                                            Disconnect
                                                        </Button>
                                                    </div>
                                                </li>
                                            ))}
                                    </ul>
                                </Tab.Pane>

                                {/* Hotmail Tab */}
                                <Tab.Pane eventKey="hotmail">
                                    <ul className="list-group mb-4">
                                        {emails && emails
                                            .filter(email => email.mail_type === 'outlook')
                                            .map((email, index) => (
                                                <li
                                                    key={index}
                                                    className="list-group-item d-flex justify-content-between align-items-center"
                                                    style={{
                                                        backgroundColor: '#f8f9fa',
                                                        padding: '15px',
                                                        borderRadius: '8px',
                                                        marginBottom: '10px',
                                                    }}
                                                >
                                                    <span>{email.email}</span>
                                                    <div>
                                                        <Link
                                                            to={`/setting-manage/mailbox/${btoa(email.id)}`}
                                                            className="btn btn-primary btn-sm mx-1"
                                                        >
                                                            Mailbox
                                                        </Link>
                                                        <Button variant="danger" className="btn-sm mx-1" onClick={() => handleDisconnect(email.id)}>
                                                            Disconnect
                                                        </Button>
                                                    </div>
                                                </li>
                                            ))}
                                    </ul>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>

                        {/* Add New Email */}
                        <div className="input-group mb-3">
                            <input
                                type="email"
                                className="form-control"
                                value={newEmail}
                                onChange={(e) => setNewEmail(e.target.value)}
                                placeholder="Enter new email"
                            />
                            <button className="btn btn-success" onClick={handleAddEmail}>
                                Add {tab === 'gmail' ? 'Gmail' : 'Hotmail'}
                            </button>
                        </div>
                    </Card.Body>
                </Card >
            </div>
        </Layout >
    );
}

export default MailboxSettings;