
// colors
export let Colors = {
    blue: "#0080FF",
    indigo: "#5b5efb",
    purple: "#8927f9",
    pink: "#f24a8b",
    red: "#df3c4e",
    orange: "#f85f32",
    yellow: "#f2bc16",
    green: "#2dc58c",
    teal: "#2acc9c",
    cyan: "#478ffc",
    black: "#000",
    white: "#fff",
    gray: "#6B7280",
    grayDark: "#374151",
    gray100: "#f1f2f7",
    gray200: "#E5E7EB",
    gray300: "#D1D5DB",
    gray400: "#6B7280",
    gray500: "#6B7280",
    gray600: "#4B5563",
    gray700: "#374151",
    gray800: "#1F2937",
    gray900: "#111827",
    primary: "#5f38f9",
    secondary: "#374151",
    success: "#2dc58c",
    info: "#478ffc",
    warning: "#f2bc16",
    danger: "#df3c4e",
    lighter: "#f1f2f7",
    light: "#E5E7EB",
    dark: "#1F2937",
    darker: "#111827",

    bodyColor: "#787c9e",
    bodyBg: "#f8f8f9",
    borderColor: "#d2d4e4",
    borderColorTranslucent: "rgba(0, 0, 0, 0.175)",

    linkColor: "#5f38f9",
    linkHoverColor: "#4c2dc7",
    codeColor: "#f24a8b",
    highlightBg: "#fcf2d0",
};

// list of all months in english word
export let monthList = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

export const truncateString = (input, count) => input.length > count ? `${input.substring(0, count)}...` : input;

export const getToday = function () {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();

    return yyyy + '-' + mm + '-' + dd;
};

export let today = getToday();

let getTesterday = function () {
    let today = new Date();
    let yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    let y_dd = String(yesterday.getDate()).padStart(2, '0');
    let y_mm = String(yesterday.getMonth() + 1).padStart(2, '0');
    let y_yyyy = yesterday.getFullYear();

    return y_yyyy + '-' + y_mm + '-' + y_dd;
};
export let yesterday = getTesterday();

let getCurrentMonth = function () {
    let today = new Date();
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();

    return yyyy + '-' + mm;
};

export let currentMonth = getCurrentMonth();

// get first and last character from string
let getInitials = function (name) {
    var names = name.split(' '),
        initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
}

let getFirstCapital = function (inputString) {
    if (!inputString) return;

    const firstLetter = inputString[0].toUpperCase();
    
    if (!firstLetter.match(/[a-zA-Z]/)) {
        const secondLetter = inputString[1] ? inputString[1].toUpperCase() : '';
        return secondLetter;
    }

    return firstLetter;
}

export let toFirstCapital = getFirstCapital;

export let toInitials = getInitials;

export let Break = { mb: 420, sm: 576, md: 768, lg: 992, xl: 1200, xxl: 1400, any: Infinity };

export const currentDateTime = () => {
    var now = new Date();
    var datetime = now.getFullYear() + '-' + (("0" + (now.getMonth() + 1)).slice(-2)) + '-' + now.getDate();
    datetime += ' ' + now.getHours() + ':' + now.getMinutes() + ':' + now.getSeconds();

    return datetime;
}

export function uToken() {
    const tokenString = localStorage.getItem("authToken");
    let token = JSON.parse(tokenString);
    if (token) {
        return token.access_token;
    } else {
        return false;
    }
}

export function validateTokenExpiration() {
    const tokenString = localStorage.getItem("authToken");
    let token = JSON.parse(tokenString);
    if (token) {
        if (token && token.expires_in) {
            const expirationTimestamp = token.expires_in * 1000;
            const currentTimestamp = Date.now();
            if (currentTimestamp > expirationTimestamp) {
                localStorage.removeItem("authToken");
                return true;
            }
        }
    }
}

export function uPublicId() {
    const tokenString = localStorage.getItem("authToken");
    let token = JSON.parse(tokenString);
    if (token) {
        return token.public_id ?? 0;
    } else {
        return 0;
    }
}

export function uStaffId() {
    const tokenString = localStorage.getItem("authToken");
    let token = JSON.parse(tokenString);
    if (token) {
        return token.staff_user_id ?? 0;
    } else {
        return 0;
    }
}

export function uInfo() {
    const tokenString = localStorage.getItem("authToken");
    let token = JSON.parse(tokenString);
    if (token) {
        return token.user ?? {};
    } else {
        return {};
    }
}

export function NumberFormat(amount) {
    if(amount == undefined){
        amount = 0;
    }
    console.log(amount)
    const formattedAmount = amount.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    return formattedAmount;
}

export function isObjectNotEmpty(obj) {
    return Object.keys(obj).length > 0;
}

export function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const convertCentToDoller = (price) => {
    return price > 0 ? '$' + Number((price / 100), 2, '.', ' ') : price;
}

export const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
}

export const convertPhoneFormat = (phone) => {
    var cleaned = ('' + phone).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {

        var intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return phone;

}

export let currencySign = "$";
